input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
}


body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.topnav {
  overflow: hidden;
  background-color: #fff3cd;
  width: 100%; /* Full width */
  height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.menssageError {
  margin: 0;
  font-size: 13px; 
  color: #c11310;
}

.menssageAviso {
  margin: 10px 0 0 0;
  font-size: 13px; 
  color: #272727;
}

.containerButtonGPay {
  display: flex;
  justify-content: center;
  justify-items: center;
  padding-top: 200px;
}

.navInfo {
  margin-top: 0 !important;
  display: flex;
  justify-content: flex-start;
}

.creditCard {
  margin-right: 5px;
  width: 300px;
  align-items: center;
  margin-bottom: 10px;
}

.field-container input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
}

.field-container {
  position: relative;
  margin-top: 10px;
}

.form-container {
  display: flex;
  max-width: 300px;
  color: #707070;
}

.cardForm {
  width: 300px;
}

.myInputs {
  display: flex;
}

.myInputs > .field-container:first-child {
  padding-right: 10px;
}

.containerForm {
  flex-direction: column;
  margin-left: 10px;
  margin-top: 60px;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: gray;
}

h2 {
  color: #272727;
  text-align: left;
  font-size: 1.2rem;
}

label {
  padding-bottom: 5px;
  font-size: 13px;
  color: #272727
}
.field-installments {
  margin: 10px 0 25px 0;
  width: 100%;
}
.field-installments label {
  display: block;
  color: #F37121;
  width: 100%;
  border-bottom: 1px #dcdcdc solid;
}
.field-installments p {
  color: #272727;
  margin: 5px 0 0 0;
  font-weight: 700
}

input {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  color: #272727;
}
select {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
}
.selectField {
  display: flex;
}

.comboParcelasButton {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  align-items: flex-start;
  display: flex;
  padding-left: 19px;
  color: rgb(170, 170, 170);
}

.arrow {
  padding-left: 264px;
  font-weight: bold;
  position: absolute;
}

.button {
  width: 160px;
  height: 40px;
  left: 0px;
  top: 140px;
  border-radius: 24px;
  border-style: hidden;
  margin-top: 10px;
}
.buttonAction {
  margin-top: 15px;
  width: 210px;
  height: 40px;
  left: 0px;
  top: 140px;
  color: #ffffff;
  background: #F37121 ;
  border-radius: 10px;
  border-style: hidden;
  border-color: #F37121 ;
  font-size: 15px;
  cursor: pointer;
}

.buttonAction:hover {
  background-color: #F37121;
}

.alert {
  margin: 5px;
}

#inline {
  display: inline;
}

.spinnerButton {
  border: 2px solid #F37121;
  border-left-color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  animation: spinButton 1s linear infinite;
  background: #F37121;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 58px;
}

@keyframes spinButton {
  to {
    transform: rotate(360deg);
  }
}

/* Absolute Center Spinner */
.loadingSpinnerScreen {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.field-checkbox {
  display: flex;
  align-items: center;
}
.field-checkbox input {
  width: 15px;
  height: 15px;
  margin-top: -5px;
}
.field-checkbox label {
  margin-top: 10px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Transparent Overlay */
.loadingSpinnerScreen:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

.creditCard{
  display: none;
}
/* :not(:required) hides these rules from IE9 and below */
.loadingSpinnerScreen:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loadingSpinnerScreen:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

@media screen and (max-width: 700px) {
  .containerForm {
    flex-direction: column-reverse;
    width: 90%;
    max-width: 300px;
    margin: 13px auto;
  }
  .cardForm {
    width: 100%;
    position: relative;
  }
  .topnav ul {
    width: 90%;
    max-width: 300px;
    margin: 13px auto;
    padding: 0
  }
  .arrow {
    padding-left: calc(100% - 40px);
  }
}
@media screen and (max-width: 350px) {
  .creditCard{
    display: none;
  }
}

/* Animation to browser*/
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
